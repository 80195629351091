import * as React from "react";
import { graphql, Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import Layout from "../components/layout.js";
import SEO from "../components/seo.js";
import mailIcon from "../images/mail-icon.svg";
import instaIcon from "../images/insta-icon.svg";

const Contact = ({ data }) => {
  const imageData = data.desktop.childImageSharp.fluid;

  const Mailto = ({ email, subject, body, ...props }) => {
    return <a href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}>{props.children}</a>;
  };

  return (
    <Layout>
      <SEO title="Contact" />
      <main className="contact">
        <BackgroundImage
          Tag="div"
          fluid={imageData}
          backgroundColor={`#000000`}
        >
          <div className="text-column-wrapper">
            <div className=" text-column">
              <h1>Hello</h1>
              <p>Got a question? Want to collaborate on a project, or just talk photography? Get in touch via email at the link below. If you like what you see here, give us a follow on Instagram. I try to post a new shot there at least once a week.</p>
              <ul className="contact-icons">
                <li>
                  <Mailto
                    email="&#104;&#101;&#108;&#108;&#111;&#064;&#111;&#110;&#101;&#115;&#116;&#114;&#101;&#101;&#116;&#099;&#105;&#116;&#121;&#046;&#099;&#111;&#109;"
                    subject=""
                    body=""
                  >
                    <div className="icon">
                      <img
                        alt="email"
                        src={mailIcon}
                      />
                    </div>
                  </Mailto>
                </li>
                <li>
                  <Link to="https://www.instagram.com/onestreetcity/">
                    <div className="icon">
                      <img
                        alt="instagram"
                        src={instaIcon}
                      />
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </BackgroundImage>
      </main>
    </Layout>
  );
};

export const query = graphql`
  query ContactQuery {
    desktop: file(name: { eq: "about-bg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default Contact;
